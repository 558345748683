export type Tipo = {
    id: number
    account_id: number
    slug: string
    titulo: string
    tipo_zap: string
    ativo: boolean
}

export class TipoRepository {
    async getAll(): Promise<Tipo[]> {
        return fetch(`tipos/get`).then(async (resp) => {
            let result = await resp.json()
            if (!resp.ok) {
                throw new Error(result.error)
            }
            return result
        })
    }
}

export const tipo_repository = new TipoRepository()

