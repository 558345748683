<template>
    <div class="card">
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col-8">
                    <h3 class="mb-0">Modulos Instalados
                    </h3>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row" >
                
                <CardExtensao @editar="editar" @installar="instalar" @remover="remover" :installed="true" v-for="record in records" :record="record"></CardExtensao>
            </div>					
        </div>
    </div>
</template>
<script lang="ts" setup>
import { extensao_repository, extensoes_instaladas } from '@/store/extensoes';
import { Extensao } from '@/store/extensoes/Extensao';
import CardExtensao from './CardExtensao.vue';
import { computed, ref, watch } from 'vue';

interface Props {
    search: string
}


const emit = defineEmits<{
    (e: 'editar', record: Extensao): void,
    (e: 'installar', record: Extensao): void,
    (e: 'remover', record: Extensao): void
}>()


const editar = (record: Extensao) => {
    emit('editar', record)
}

const instalar = (record: Extensao) => {
    emit('installar', record)
}

const remover = (record: Extensao) => {
    emit('remover', record)
}


const props = defineProps<Props>()

const extensoes = ref<Extensao[]>([])

const search = async () => {
    extensoes.value = await extensao_repository.search({
        keywords: props.search
    })
}

watch(extensoes_instaladas, () => {
    search()
})

const records = computed(() => {    
    
    return extensoes.value.filter(row => {        
        return extensoes_instaladas.value.includes(row.id)
    })
})



search()

watch(() => props.search, search)

</script>