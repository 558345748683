import EditorCodigo from "@/modulos/extensoes/editor/EditorCodigo.vue";
import { Extensao } from "./Extensao";
import { SearchFilter } from "./SearchFilter";


export class ExtensaoRepository {
    private records: Map<string, Extensao> = new Map();

    add(record: Extensao) {
        if (!record.editor) {
            record.editor = EditorCodigo;
        }
        this.records.set(record.id, record);
    }

    async search({ keywords }: SearchFilter = { keywords: '' }): Promise<Extensao[]> {
        return Array.from(this.records.values()).filter(row => {
            return `${row.titulo} ${row.descricao}`.match(new RegExp(keywords.replace(/\s+/igm, '|'), 'i'));
        });
    }
}
