<template>
    <div class="form-group">
        <p>Informe o <b>Webservice Key</b> e clique no botão
            <ArrowRepeatIcon />
            e aguarde os tipos de imóveis do Jetimob.
        </p>

        <label for="configuracoes-codigo" class="form-label">Webservice Key</label>
        <div class="input-group">
            <input type="text" class="form-control" id="configuracoes-codigo" v-model="configuracoes.webservice_key" />
            <div class="input-group-append">
                <button class="btn btn-primary" type="button" @click="atualizarWebserviceKey" title="Atualizar">
                    <ArrowRepeatIcon :class="{spin: atualizando}" />
                </button>
            </div>
        </div>

    </div>
    <div class="row" v-if="Categorias.length !=0 ">
        <div class="col-12 mb-3">
            <h5 class="title">
                Equivalência de Tipos
            </h5>
            <p>Selecione o tipo do Lead Lar esquivalente a cada tipo do Jetimob.</p>
        </div>
        <div class="col-12">
            <div class="row mb-3">
                <div class="col-6">Jetimob</div>
                <div class="col-6">Lead Lar</div>
            </div>
            <div class="row mb-3" v-for="categoria_config in Categorias">
                <div class="col-6">
                    <input type="text" class="form-control" disabled readonly
                        :value="categoria_config.categoria_jetimob">
                </div>
                <div class="col-6">
                    <select class="form-control" v-model="categoria_config.categoria_id" @change="syncCategorias">
                        <option :value="0" :is_selected="0 === Number(categoria_config.categoria_id)">Inativo </option>
                        <option :value="tipo.id" v-for="tipo in tipos">{{ tipo.titulo }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div class="row" v-if="Caracteristicas.length != 0">
        <div class="col-12 mb-3">
            <h5 class="title">
                Equivalência de Características
            </h5>
            <p>Selecione o tipo do Lead Lar esquivalente a cada característica do Jetimob.</p>
        </div>
        <div class="col-12">
            <div class="row mb-3">
                <div class="col-6">Jetimob</div>
                <div class="col-6">Lead Lar</div>
            </div>
            <div class="row mb-3" v-for="caracteristica_config in Caracteristicas">
                <div class="col-6">
                    <input type="text" class="form-control" disabled readonly
                        :value="caracteristica_config.caracteristica_jetimob">
                </div>
                <div class="col-6">
                    <select class="form-control" v-model="caracteristica_config.caracteristica_id" @change="syncCaracteristicas">
                        <option :value="0" :is_selected="0 === Number(caracteristica_config.caracteristica_id)" >Inativo</option>
                        <option :value="caracteristica.id" v-for="caracteristica in caracteristicas">{{ caracteristica.titulo }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>

import { computed, ref, watch } from 'vue';
import ArrowRepeatIcon from "bootstrap-icons/icons/arrow-repeat.svg?component"
import { Tipo, tipo_repository } from '@/store/tipos';
import { Caracteristica, caracteristica_repository } from '@/store/caracteristicas';

import Slugify from 'slugify';

const slugify = (str: string): string  => {
    return Slugify(str, {
        locale: 'pt',
        lower: true        
    })
}

type JetimobResponse = {
    categorias: string[]
    caracteristicas: string[]
    infra_estrutura: string[]
}

type CategoriaConfig = {
    categoria_jetimob: string
    categoria_id: number
}

type CaracteristicaConfig = {
    caracteristica_jetimob: string
    caracteristica_id: number
}


interface Props {
    modelValue: Record<string, any>
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: Record<string, any>): void
}>()

const atualizando = ref<boolean>(false)
const configuracoes = ref<Record<string, any>>({ ...props.modelValue } || {})
const tipos = ref<Tipo[]>([])
const caracteristicas = ref<Caracteristica[]>([])

const Categorias = computed(() => {
    if ('categorias' in configuracoes.value) {
        (configuracoes.value.categorias as CategoriaConfig[]).sort((a, b) => {
            return slugify(a.categoria_jetimob) < slugify(b.categoria_jetimob) ? -1 : 1
        })
        return configuracoes.value.categorias as CategoriaConfig[]
    }
    return [] as CategoriaConfig[]
})

const Caracteristicas = computed(() => {
    if ('caracteristicas' in configuracoes.value) {
        (configuracoes.value.caracteristicas as CaracteristicaConfig[]).sort((a, b) => {
            return slugify(a.caracteristica_jetimob) < slugify(b.caracteristica_jetimob) ? -1 : 1
        })
        
        return configuracoes.value.caracteristicas as CaracteristicaConfig[]
    }
    return [] as CaracteristicaConfig[]
})

watch(() => configuracoes.value.webservice_key, () => {
    emit('update:modelValue', { ...configuracoes.value })
})

const syncCategorias = () => {
    emit('update:modelValue', {
        ...configuracoes.value,
        categorias: Categorias.value
    })
}

const syncCaracteristicas = () => {
    emit('update:modelValue', {
        ...configuracoes.value,
        caracteristicas: Caracteristicas.value
    })
}

watch(() => props.modelValue, () => {
    configuracoes.value = { ...props.modelValue }
})

const update_relacao_caracteristicas = () => {
    for (let c of Caracteristicas.value) {
        if (Number(c.caracteristica_id) != 0) {
            continue
        }
        let slug_jet = slugify(c.caracteristica_jetimob)
        let caracteristica = caracteristicas.value.find((row) => {            
            return row.slug === slug_jet
        })
        if (caracteristica) {
            c.caracteristica_id = caracteristica.id
            syncCaracteristicas()
        }
    }
}

const update_relacao_tipos = () => {
    for (let c of Categorias.value) {
        if (Number(c.categoria_id) != 0) {
            continue
        }
        let slug_jet = slugify(c.categoria_jetimob)
        let tipo = tipos.value.find((row) => {
            return row.slug === slug_jet
        })
        if (tipo) {
            c.categoria_id = tipo.id
            syncCategorias()
        }
    }
}




const atualizarWebserviceKey = async () => {
    let webservice_key = configuracoes.value.webservice_key
    if (!webservice_key) {
        return
    }    

    atualizando.value = true
    try {
        
        //https://api.jetimob.com/webservice/CXuuDvXM8tA1m9y4tCZKAQ4mCwpEDZhVLbxpyLLI/imoveis/
        let response = await fetch(`jetimob/configuracoes?webservice_key=${webservice_key}`)
        if (!response.ok) {
            console.error(await response.text())
        }
        let jetimob_response = await response.json() as JetimobResponse
    
        let categorias: Map<string, CategoriaConfig> = new Map()
        let caracteristicas: Map<string, CaracteristicaConfig> = new Map()
        
        if ('categorias' in configuracoes.value) {
            for (let row of (configuracoes.value.categorias as CategoriaConfig[])) {
                categorias.set(row.categoria_jetimob, row)
            }
        }    

        if ('caracteristicas' in configuracoes.value) {
            for (let row of (configuracoes.value.caracteristicas as CaracteristicaConfig[])) {
                caracteristicas.set(row.caracteristica_jetimob, row)
            }
        }    
    
        for (let categoria_jetimob of jetimob_response.categorias) {
            let categoria = categorias.get(categoria_jetimob)
            if (!categoria) {            
                categorias.set(categoria_jetimob, {
                    categoria_jetimob,
                    categoria_id: 0
                })            
            }
        }
        for (let caracteristica_jetimob of jetimob_response.caracteristicas) {
            let categoria = caracteristicas.get(caracteristica_jetimob)
            if (!categoria) {            
                caracteristicas.set(caracteristica_jetimob, {
                    caracteristica_jetimob,
                    caracteristica_id: 0
                })            
            }
        }
    
        for (let caracteristica_jetimob of jetimob_response.infra_estrutura) {
            let categoria = caracteristicas.get(caracteristica_jetimob)
            if (!categoria) {            
                caracteristicas.set(caracteristica_jetimob, {
                    caracteristica_jetimob,
                    caracteristica_id: 0
                })            
            }
        }
    
        configuracoes.value.categorias = Array.from(categorias.values())
        configuracoes.value.caracteristicas = Array.from(caracteristicas.values())
    } catch (error) {
        console.error(error)
    }
    atualizando.value = false
}

const updateCaracteristicas = async () => {
    try {
        caracteristicas.value = await caracteristica_repository.search()
        update_relacao_caracteristicas()
    } catch (error) {
        console.error(error)
    }
}

const updateTipos = async () => {
    try {
        tipos.value = await tipo_repository.getAll()
        update_relacao_tipos()
    } catch (error) {
        console.error(error)
    }
}

updateTipos()
updateCaracteristicas()
</script>

<style>
/* CSS */
.spin {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

select:has(option[is_selected="true"]) {
    color: rgba(255,0,0,0.5);
}
</style>