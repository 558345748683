import { Caracteristica } from "./caracteristica"

export class CaracteristicaRepository {
    async search(): Promise<Caracteristica[]> {
        try {
            let response = await fetch<Caracteristica[]>(`caracteristicas/search`)
            if (!response.ok) {
                throw new Error("Não foi possível carregar as características")
            }
            let records = await response.json()
            return records
        } catch (error) {
            throw error
        }
    }
}