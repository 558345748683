<template>
    <div class="card m-2" style="width: 30rem;">

        <div class="card-body">
            <div style="height: 20rem;">
                <div class="pb-3 mb-4 border-bottom" style="min-height: 67px">
                    <img height="50"  v-if="record.imagem != ''"  :src="record.imagem" :alt="record.titulo">
                </div>
                <h2 class="card-title">{{ record.titulo }}</h2>
                <p class="card-text">{{ record.descricao }}</p>
            </div>
            
            <div class="row align-items-center border-top pt-3">
                <div class="col-6">
                    <strong v-if="record.gratuito && !installed">Gratuito</strong>
                    <a href="javascript:void(0)" class="btn btn-primary" v-if="installed" @click="editar">Configurar</a>
                </div>
                <div class="col-6 text-right">
                    <a href="javascript:void(0)" class="btn btn-primary" v-if="!installed" @click="instalar">Instalar</a>
                    <a href="javascript:void(0)" class="btn btn-danger" v-if="installed && !['google-tag-manager'].includes(record.id)" @click="remover">
                        <i class="fas fa-trash"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>

</template>
<script lang="ts" setup>
import { Extensao } from '@/store/extensoes/Extensao';



interface Props {
    record: Extensao,
    installed: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
    (e: 'editar', record: Extensao): void,
    (e: 'installar', record: Extensao): void,
    (e: 'remover', record: Extensao): void
}>()


const editar = () => {
    emit('editar', props.record)
}

const instalar = () => {
    emit('installar', props.record)
}

const remover = () => {
    emit('remover', props.record)
}

</script>