import Swal from "sweetalert2"

const $el: HTMLDivElement | null = document.querySelector('#modal-cpf-cnpj')
if ($el) {
    $($el).modal({
        backdrop: 'static',
        focus: true,
        keyboard: false,
        show: true
    })

    $(document).on('input', '[alt=cpf_cnpj]', function () {
        const $input = $(this)
        let value = $input.val() as string
        value = value.replace(/\D+/igm, '')
        
        if (value.length <= 11) {
            /** @ts-ignore */
            $input.setMask('999.999.999-999')
        } else {
            /** @ts-ignore */
            $input.setMask('cnpj')
        }

    })

    $(document).on('input', '[alt=phone]', function () {
        const $input = $(this)
        let value = $input.val() as string
        value = value.replace(/\D+/igm, '')
        
        if (value.length <= 10) {
            /** @ts-ignore */
            $input.setMask('(99) 9999-99999')
        } else {
            /** @ts-ignore */
            $input.setMask('(99) 99999-9999')
        }

    })

    let enviando: boolean = false

    $(document).on('submit', '[name=formCpfCnpj]', function () {
        const handle = async ($form: JQuery<HTMLFormElement>) => {
            const $btn = $form.find('[type=submit]') as JQuery<HTMLButtonElement>
            try {
                if (enviando) {
                    return
                }
                enviando = true
                $btn.html("Salvando...")
                $btn.prop('disabled', 'disabled')

                let $cpf_cnpj: JQuery<HTMLInputElement> = $form.find('[name=cpf_cnpj]') as JQuery<HTMLInputElement>
                let cpf_cnpj = $cpf_cnpj.val() as string
                cpf_cnpj = cpf_cnpj.replace(/\D+/igm, '')
                if (![11, 14].includes(cpf_cnpj.length)) {
                    throw new Error("Informe o CPF/CNPJ corretamente"+cpf_cnpj.length)
                }

                let $telefone: JQuery<HTMLInputElement> = $form.find('[name=telefone]') as JQuery<HTMLInputElement>
                let telefone = $telefone.val() as string
                telefone = telefone.replace(/\D+/igm, '')
                if (![10, 11].includes(telefone.length)) {
                    throw new Error("Informe o Celular corretamente"+telefone.length)
                }

                
                await $.ajax({
                    url: $form.attr('action'),
                    type: 'post',
                    dataType: 'json',
                    data: {
                        cpf_cnpj: $cpf_cnpj.val(),
                        telefone: $telefone.val()
                    }
                })

                await Swal.fire({
                    icon: "success",
                    title: "Cadastro atualizado com sucesso!"
                })

                location.reload()


            } catch (error: any) {
                console.error(error)
                let title = 'Não foi possível enviar o formulário'
                if (error instanceof Error) {
                    title = error.message
                } else {
                    title = error?.responseJSON?.error || 'Não foi possível enviar o formulário'
                }
                Swal.fire({
                    icon: 'error',
                    title
                })
            }
            enviando = false
            $btn.html('Salvar')
            $btn.prop('disabled', false)

        }

        handle($(this))

        return false
    })
}