<template>
    <div>
        <div class="form-group">
            <label for="configuracoes-codigo" class="form-label">Chave de integração</label>
            <input type="text" class="form-control" id="configuracoes-codigo" v-model="configuracoes_codigo" />
        </div>
        <div class="form-group">
            <label for="configuracoes-endpoint" class="form-label">URL de integração</label>
            <input type="text" class="form-control" id="configuracoes-endpoint" v-model="configuracoes_endpoint" />
        </div>
        <div class="row mb-4">
            <div class="col-12 text-center">
                <button v-if="!atualizando" @click="atualizarCategoriasCaracteristicas" type="button"
                    class="btn btn-secondary">Atualizar Categorias e Características</button>
                <button v-if="atualizando" type="button" class="btn btn-secondary disabled"
                    disabled>Atualizando...</button>
            </div>
        </div>


        <div class="row" v-if="configuracoes_categorias.length != 0">
            <div class="col-12 mb-3">
                <h5 class="title">
                    Equivalência de Tipos
                </h5>
                <p>Selecione o tipo do Lead Lar esquivalente a cada tipo do vistasoft.</p>
            </div>
            <div class="col-12">
                <div class="row mb-3">
                    <div class="col-6">vistasoft</div>
                    <div class="col-6">Lead Lar</div>
                </div>
                <div class="row mb-3" v-for="categoria_config in configuracoes_categorias">
                    <div class="col-6">
                        <input type="text" class="form-control" disabled readonly
                            :value="categoria_config.categoria_vistasoft">
                    </div>
                    <div class="col-6">
                        <select class="form-control" v-model="categoria_config.categoria_id">
                            <option :value="0" :is_selected="0 === Number(categoria_config.categoria_id)">Inativo
                            </option>
                            <option :value="tipo.id" v-for="tipo in tipos">{{ tipo.titulo }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="configuracoes_caracteristicas.length != 0">
            <div class="col-12 mb-3">
                <h5 class="title">
                    Equivalência de Características
                </h5>
                <p>Selecione o tipo do Lead Lar esquivalente a cada característica do vistasoft.</p>
            </div>
            <div class="col-12">
                <div class="row mb-3">
                    <div class="col-6">vistasoft</div>
                    <div class="col-6">Lead Lar</div>
                </div>
                <div class="row mb-3" v-for="caracteristica_config in configuracoes_caracteristicas">
                    <div class="col-6">
                        <input type="text" class="form-control" disabled readonly
                            :value="caracteristica_config.caracteristica_vistasoft">
                    </div>
                    <div class="col-6">
                        <select class="form-control" v-model="caracteristica_config.caracteristica_id" >
                            <option :value="0" :is_selected="0 === Number(caracteristica_config.caracteristica_id)">
                                Inativo</option>
                            <option :value="caracteristica.id" v-for="caracteristica in caracteristicas">{{
                                caracteristica.titulo }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script lang="ts" setup>

import { computed, ref, watch } from 'vue';
import Slugify from 'slugify';
import { Tipo, tipo_repository } from '@/store/tipos';
import { Caracteristica, caracteristica_repository } from '@/store/caracteristicas';

const slugify = (str: string): string => {
    return Slugify(str, {
        locale: 'pt',
        lower: true
    })
}

type VistaResponse = {
    categorias: string[]
    caracteristicas: string[]
}

type CategoriaConfig = {
    categoria_vistasoft: string
    categoria_id: number
}

type CaracteristicaConfig = {
    caracteristica_vistasoft: string
    caracteristica_id: number
}

interface Props {
    modelValue: Record<string, any>
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: Record<string, any>): void
}>()

const atualizando = ref<boolean>(false)
const tipos = ref<Tipo[]>([])
const caracteristicas = ref<Caracteristica[]>([])

const configuracoes_codigo = ref<string>(props.modelValue?.codigo || '')
const configuracoes_endpoint = ref<string>(props.modelValue?.endpoint || '')
const configuracoes_categorias = ref<CategoriaConfig[]>(props.modelValue?.categorias || [])
const configuracoes_caracteristicas = ref<CaracteristicaConfig[]>(props.modelValue?.caracteristicas || [])





const atualizarCategoriasCaracteristicas = async () => {
    let codigo = configuracoes_codigo.value
    if (!codigo) {
        return
    }
    let endpoint = configuracoes_endpoint.value
    if (!endpoint) {
        return
    }

    atualizando.value = true
    try {

        const loadVista = async (): Promise<VistaResponse> => {
            let response = await fetch(`vista-soft/configuracoes?codigo=${codigo}&endpoint=${endpoint}`)
            if (!response.ok) {
                console.error(await response.text())
            }
            let vistasoft_response = await response.json() as VistaResponse
            return vistasoft_response
        }
        

        let [caracteristicas_leadlar, categorias_leadlar, vistasoft_response] = await Promise.all([
            caracteristica_repository.search().then(rows => {
                let result: Map<string, number> = new Map()
                for (let row of rows) {
                    result.set(row.slug, row.id)
                }
                return result
            }),
            tipo_repository.getAll().then(rows => {
                let result: Map<string, number> = new Map()
                for (let row of rows) {
                    result.set(row.slug, row.id)
                }
                return result
            }),
            loadVista()
        ])


        let categorias: Map<string, CategoriaConfig> = new Map()
        let caracteristicas: Map<string, CaracteristicaConfig> = new Map()

        
        for (let row of (configuracoes_categorias.value)) {
            categorias.set(row.categoria_vistasoft, row)
        }
    

        for (let row of (configuracoes_caracteristicas.value)) {
            caracteristicas.set(row.caracteristica_vistasoft, row)
        }

        for (let categoria_vistasoft of vistasoft_response.categorias) {
            let categoria = categorias.get(categoria_vistasoft)
            if (!categoria) {
                let slug = slugify(categoria_vistasoft)
                let categoria_id = categorias_leadlar.get(slug)
                if (!categoria_id) {
                    categoria_id = 0
                }

                categorias.set(categoria_vistasoft, {
                    categoria_vistasoft,
                    categoria_id
                })
            }
        }
        for (let caracteristica_vistasoft of vistasoft_response.caracteristicas) {
            let categoria = caracteristicas.get(caracteristica_vistasoft)
            if (!categoria) {
                let slug = slugify(caracteristica_vistasoft)
                let caracteristica_id = caracteristicas_leadlar.get(slug)
                if (!caracteristica_id) {
                    caracteristica_id = 0
                }

                caracteristicas.set(caracteristica_vistasoft, {
                    caracteristica_vistasoft,
                    caracteristica_id
                })
            }
        }        

        configuracoes_categorias.value = Array.from(categorias.values())
        configuracoes_caracteristicas.value = Array.from(caracteristicas.values())
    } catch (error) {
        console.error(error)
    }
    atualizando.value = false
}

const updateCaracteristicas = async () => {
    try {
        caracteristicas.value = await caracteristica_repository.search()        
    } catch (error) {
        console.error(error)
    }
}

const updateTipos = async () => {
    try {
        tipos.value = await tipo_repository.getAll()
    } catch (error) {
        console.error(error)
    }
}

updateTipos()
updateCaracteristicas()

watch(configuracoes_codigo, () => {
    emit('update:modelValue', {
        ...props.modelValue,
        codigo: configuracoes_codigo.value
    })
})

watch(configuracoes_endpoint, () => {
    emit('update:modelValue', {
        ...props.modelValue,
        endpoint: configuracoes_endpoint.value
    })
})

watch(configuracoes_categorias, () => {
    emit('update:modelValue', {
        ...props.modelValue,
        categorias: configuracoes_categorias.value
    })
})

watch(configuracoes_caracteristicas, () => {
    emit('update:modelValue', {
        ...props.modelValue,
        caracteristicas: configuracoes_caracteristicas.value
    })
})

watch(() => props.modelValue.codigo, () => {
    configuracoes_codigo.value = props.modelValue?.codigo || ''
})
watch(() => props.modelValue.endpoint, () => {
    configuracoes_endpoint.value = props.modelValue?.endpoint || ''
})
watch(() => props.modelValue.caracteristicas, () => {
    configuracoes_caracteristicas.value = props.modelValue?.caracteristicas || []
})
watch(() => props.modelValue.categorias, () => {
    configuracoes_categorias.value = props.modelValue?.categorias || []
})

</script>