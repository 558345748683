<template>    
    <div class="form-group">
        <label for="configuracoes-codigo" class="form-label">Código</label>
        <input type="text" class="form-control" id="configuracoes-codigo" v-model="configuracoes.codigo" />
    </div>
</template>
<script lang="ts" setup>

import { ref, watch } from 'vue';


interface Props {
    modelValue: Record<string, any>
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: Record<string, any>): void
}>()

const configuracoes = ref<Record<string, any>>({...props.modelValue}||{})

watch(() => configuracoes.value.codigo, () => {
    
    emit('update:modelValue', {...configuracoes.value})
})

watch(() => props.modelValue, () => {
    configuracoes.value = {...props.modelValue}
})

</script>