<template>
    <!-- Modal -->
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-labelledby="modalEditor" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" :class="{'modal-xl': large}" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalEditor">{{ titulo }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent.stop="onSubmit">
                    <div class="modal-body">
                        <slot></slot>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" class="btn btn-primary" v-if="show_confirm_button">{{ confirm_button_text || 'Adicionar' }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    
</template>
<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';


interface Props {
    titulo: string
    confirm_button_text?: string
    show_confirm_button?: boolean
    show?: boolean,
    large?: boolean
}

const emits = defineEmits<{
    (e: 'submit'): void,
    (e: 'close'): void,
}>()


const props = defineProps<Props>()
const modal = ref<any>(null)

const onSubmit = () => {
    emits('submit')
}

onMounted(() => {
    if (props.show) {
        /** @ts-ignore */
        $(modal.value).modal('show')
    }
    $(modal.value).on('hide.bs.modal', () => {
        emits('close')
    })
})

watch(() => props.show, () => {
    if (props.show) {
        /** @ts-ignore */
        $(modal.value).modal('show')
    } else {
        /** @ts-ignore */
        $(modal.value).modal('hide')
        emits('close')
    }
})

onBeforeUnmount(() => {
    /** @ts-ignore */
    $(modal.value).modal('hide')
})

</script>
<style>
    .modal-body {
        max-height: 60vh;
    }
</style>