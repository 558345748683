import Swal from 'sweetalert2';

export {}

if (document.querySelector('[name=formEditar]')) {
    import('./form');
}


$(document).on('click', '[data-id][data-folder].btn-remover', function () {
    const $self = $(this);
    const $btn = $self.find('span');
    const id = $self.data('id')
    const folder = $self.data('folder')

    if ($self.data('enviando')) {
        return
    }

    (async () => {
        try {
            const { isConfirmed } = await Swal.fire({
                icon: "warning",
                title: "Deseja remover o registro?",
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonText: "Sim",
                denyButtonText: "Não"
            })
            if (!isConfirmed) {
                return
            }



            $self.data('enviando', true)
            $btn.html('EXCLUINDO...')
            await $.ajax({
                url: `${folder}/remove`,
                type: 'delete',
                dataType: 'json',
                data: {
                    id
                }
            })

            await Swal.fire({
                icon: "success",
                title: "Registro removido com sucesso!"
            })

            location.reload()

        } catch (error: any) {
            const text = error?.responseJSON?.error || error?.message || "Não foi possível remover o tipo"
            Swal.fire({
                icon: 'error',
                title: 'Oops',
                text
            })
        } finally {
            $self.data('enviando', false)
            $btn.html('EXCLUIR')
        }
    })()

    return false
})